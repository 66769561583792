var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"customers-pending-approval-view"}},[(_vm.errorMessages.length > 0)?_c('v-alert',{attrs:{"prominent":"","type":"error"}},_vm._l((_vm.errorMessages),function(message,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(message)+" "),_c('br')])}),0):_vm._e(),_c('material-card',{attrs:{"color":"primary","icon":"mdi-account-check"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Customers Pending Verification ")]},proxy:true}])},[_c('v-data-table',{staticClass:"mr-2 ml-2",attrs:{"single-select":false,"show-select":"","item-key":"id","headers":_vm.headers,"items":_vm.customers,"loading":_vm.loading,"loading-text":"Loading Customers... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"placeholder":"Search","hide-details":"","clearable":"","append-icon":"mdi-magnify","single-line":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"disabled":_vm.selected.length === 0,"min-width":"0","text":"","outlined":"","color":"accent","elevation":"2"}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_c('v-list-item',{on:{"click":_vm.approveSelected}},[_c('v-list-item-title',[_vm._v("Approve")])],1),_c('v-list-item',{on:{"click":_vm.approveAddCart}},[_c('v-list-item-title',[_vm._v("Approve & Add To Cart")])],1),_c('v-list-item',{on:{"click":_vm.deleteSelected}},[_c('v-list-item-title',[_vm._v("Decline")])],1)],1)],1)],1)]},proxy:true},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.customer.full_name))]),_c('br'),_vm._v(" "+_vm._s(item.customer.shipping_address.street1)+" "+_vm._s(item.customer.shipping_address.street2)+" "),_c('br'),_vm._v(" "+_vm._s(item.customer.shipping_address.city)+", "+_vm._s(item.customer.shipping_address.state.name)+" "+_vm._s(item.customer.shipping_address.zip)+" ")]}},{key:"item.customer.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.customer.validation_errors),function(validation,index){return _c('v-chip',{key:index,staticClass:"ma-2",attrs:{"color":"red","text-color":"white","small":""}},[_vm._v(" "+_vm._s(validation.error)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openValidation(item.customer)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-check")])],1),_c('v-list-item-title',[_vm._v("View Validation")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'customer_detail', params: { id: item.customer.id } })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-search")])],1),_c('v-list-item-title',[_vm._v("View Customer")])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No customers pending verification found. ")]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('customer-validation-modal',{ref:"customerValidation"}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }